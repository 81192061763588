import { Component, OnInit, ViewEncapsulation, Inject, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange, MatDialogConfig, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { AssessmentsService } from 'app/support/services/assessments.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { settings } from 'cluster';
import { element } from 'protractor';
import { ActivateDialogComponent } from '../activate-dialog/activate-dialog.component';
import { LocationCreateComponent } from 'app/features/main/clinics/location-create/location-create.component';
import { ListDialogComponent } from '../list-dialog/list-dialog.component';
@Component({
  selector: 'app-assessment-general-settings',
  templateUrl: './assessment-general-settings.component.html',
  styleUrls: ['./assessment-general-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssessmentGeneralSettingsComponent implements OnInit {

  @ViewChildren('matSelect_j') matSelect_j;
  addSettingsForm: FormGroup;
  alertForm: FormGroup;
  selected_rule: Array<any> = [];
  deleted_rules: Array<any> = [];
  intervalHrsList: any = []
  intervalMinsList: any = []
  retryCountList: any = []
  rulesList: FormArray;
  updatedAlert: Array<any> = [];
  durationHrsList: any = []
  durationMinsList: any = []
  settings: boolean = true;
  alerts: boolean = false;
  is_update: boolean = false;
  checkAlert: Array<any> = [];
  clinicianList: Array<any> = [];
  valueChange: boolean = false;
  genderDependencyList = [
    {value: 'both', name: 'All', id: 1},
    {value: 'female', name: 'Female', id: 2},
    {value: 'male', name: 'Male', id: 3}
  ];
  locationList: Array<any> = [];
  event: any;
  assessmentId: any;

  constructor(
    public dialogRef: MatDialogRef<AssessmentGeneralSettingsComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: SnackbarCongiration,
    private assessmentService: AssessmentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    if (this.data && this.data.hasOwnProperty('update_data')) {
      this.is_update = true;
    }
    this.buildForm()
  }

  buildForm() {
    this.addSettingsForm = this.fb.group({
      // time_duration_hrs: [this.is_update ? this.data['update_data']['time_duration_hrs'] : 0, Validators.required],
      // time_duration_mins: [this.is_update ? this.data['update_data']['time_duration_mins'] : 0, Validators.required],
      // time_interval_hrs: [this.is_update ? this.data['update_data']['time_interval_hrs'] : 0, Validators.required],
      // time_interval_mins: [this.is_update ? this.data['update_data']['time_interval_mins'] : 15, Validators.required],
      // retry_count: [this.is_update ? this.data['update_data']['retry_count'] : 0, Validators.required],
      // gender_dependency: [this.is_update ? this.data['update_data']['gender_dependency'] : 'both', Validators.required]
    })

    if (this.data.type == 'assessment') {
      this.addSettingsForm.addControl('gender_dependency', new FormControl(this.is_update ? this.data['update_data']['gender_dependency'] : 'both', Validators.required))
    }
    else {
      this.intervalHrsList = this.data['general_settings_data']['time_interval_hrs'];
      this.intervalMinsList = this.data['general_settings_data']['time_interval_mins'];
      this.durationHrsList = this.data['general_settings_data']['time_duration_hrs'];
      this.durationMinsList = this.data['general_settings_data']['time_duration_mins'];
      this.retryCountList = this.data['general_settings_data']['max_retry_count_list'];
      this.locationList = this.data['locations'];
      this.rulesList = this.data['alert_data']['alert_rule'];
      if(this.data['alert_data']['assessment_scoring'] == false)
      {
          if(this.rulesList.length>0)
          {
              for(var i=0; i<this.rulesList.length; i++)
              {
                this.rulesList[i]["value"] = this.rulesList[i]["value"].split(".")[0];
              }
          } 
      }

      this.updatedAlert = this.data['alert_data']['clinic_alerts'];
      this.updatedAlert.forEach(element => {
        this.checkAlert.push(element['alert_rule']);
      })
      let assigned_clinician = [{
        "id": -1,
        "name": "Assigned Clinician"
      }];
      this.data['clinicians'] = assigned_clinician.concat(this.data['clinicians']);
      this.clinicianList = this.data['clinicians'];
      // this.addSettingsForm.addControl('time_duration_days', new FormControl(this.is_update ? this.data['update_data']['time_duration_days'] : 0, Validators.required))
      // this.addSettingsForm.addControl('time_duration_hrs', new FormControl(this.is_update ? this.data['update_data']['time_duration_hrs'] : 0, Validators.required))
      // this.addSettingsForm.addControl('time_duration_mins', new FormControl(this.is_update ? this.data['update_data']['time_duration_mins'] : 0, Validators.required))
      this.addSettingsForm.addControl('time_interval_days', new FormControl(this.is_update ? this.data['update_data']['time_interval_days'] : 0, Validators.required))
      this.addSettingsForm.addControl('time_interval_hrs', new FormControl(this.is_update ? this.data['update_data']['time_interval_hrs'] : 0, Validators.required))
      this.addSettingsForm.addControl('time_interval_mins', new FormControl(this.is_update ? this.data['update_data']['time_interval_mins'] : 15, Validators.required))
      this.addSettingsForm.addControl('retry_count', new FormControl(this.is_update ? this.data['update_data']['retry_count'] : 0, Validators.required))
      if (this.addSettingsForm.get('time_interval_hrs').value == 0 && this.addSettingsForm.get('time_interval_mins').value == 0 && this.addSettingsForm.get('time_interval_days').value == 0) {
        this.addSettingsForm.get('time_interval_mins').setValue(15);
      }
      if ('id' in this.data['general_settings']) {
        this.addSettingsForm.controls['retry_count'].setValue(this.data['general_settings']['retry_count'])
        this.addSettingsForm.controls['time_interval_days'].setValue(this.data['general_settings']['time_interval_days'])
        this.addSettingsForm.controls['time_interval_hrs'].setValue(this.data['general_settings']['time_interval_hrs'])
        this.addSettingsForm.controls['time_interval_mins'].setValue(this.data['general_settings']['time_interval_mins'])
      }
      else if (this.is_update) {
        this.data['general_settings']['time_interval_days'] = this.data['update_data']['time_interval_days'];
        this.data['general_settings']['time_interval_hrs'] = this.data['update_data']['time_interval_hrs'];
        this.data['general_settings']['time_interval_mins'] = this.data['update_data']['time_interval_mins'];
        this.data['general_settings']['retry_count'] = this.data['update_data']['retry_count'];
      }

      this.alertForm = this.fb.group({
        rules_list: this.fb.array([])
      });
      this.rulesList = this.alertForm.get('rules_list') as FormArray;
      this.data['alert_data']['alert_rule'].forEach(element => {
        this.rulesList.push(this.fb.group({
          is_alert: element['is_alert'] ? element['is_alert'] : false,
          id: element['id'],
          rule_name: element['rule_name'],
          type_in_word: element['type_in_word'],
          operator_in_word: element['operator_in_word'],
          value: element['value'],
          selectAllClinicians: element['selectAllClinicians'] ? element['selectAllClinicians'] : false,
          clinicians: element['clinicians'],
          locations: element['locations'] ? element['locations'] : [],
          allLocations: element['allLocations'] ? element['allLocations'] : false
          // assigned_clinician: element['assigned_clinician'] ? element['assigned_clinician'] : false,
        }))
      });
      // this.alertForm.addControl('clinician', new FormControl);
      // this.alertForm.addControl('selectAllClinician',new FormControl(false));
      this.rulesList.controls.forEach((elem, ind) => {
        this.updatedAlert.forEach(el => {
          if (el['alert_rule'] == elem.value['id']) {
            // if (el['assigned_clinician']) {
            //   elem.get('assigned_clinician').setValue(true);
            // }
            // else {
            //   elem.get('assigned_clinician').setValue(false);
            // }
            elem.get('is_alert').setValue(true);
            let selected_user = [];
            this.clinicianList.filter((element: any) => {
              for (let selected of el['user_list'])
                if (element['id'] == selected['id']) selected_user.push(element);
            });
            elem.get('clinicians').setValue(selected_user);
            elem.get('clinicians').setValidators([Validators.required]);
            if(el['all_clinician'] == true){
              elem.get('selectAllClinicians').setValue(true);
              elem.get('clinicians').setValue(this.clinicianList.concat([]));
            }
            if(el['location_list']){
              let selected_loc = [];
              this.locationList.filter((element:any) => {
                for (let selected of el['location_list'])
                if (element['id'] == selected['id']) selected_loc.push(element);
              })
              elem.get('locations').setValue(selected_loc);
            }
            elem.get('allLocations').setValue(el['assigned_location']);
          }
        })
      })
    }
  }

  checkValueChanges() {
    if(this.alertForm!=undefined){
      let alertData = this.alertForm.value['rules_list'];
      let bool = false;
      let chkalert = [];
      let listCkh = [];
      let locCkh = [];
      let loc_list = [], loc_data = [];
      let users_list = [], clinician_list = [];
      let settingsData = this.addSettingsForm.value;
      alertData.forEach(ele => {
        // listCkh = [];
        if (ele['is_alert']) {
          chkalert.push(ele['id'])
        }
        if (this.checkAlert.includes(ele['id'])) {
          this.updatedAlert.forEach(el => {
            if (ele['id'] == el['alert_rule']) {
              if (ele['is_alert'] == el['is_alert']) {
                if(el['all_clinician'] == true && ele['selectAllClinicians'] == true){
                  listCkh.push(true)
                }else{
                  listCkh.push(ele['clinicians'].length == el['user_list'].length)
                }
                if(ele['allLocations'] == true && el['assigned_location'] == true){
                  locCkh.push(true)
                }else{
                  locCkh.push(ele['locations'].length == el['location_list'].length)
                }
                if (ele['clinicians'].length == el['user_list'].length) {
                  ele['clinicians'].forEach(e => {
                    clinician_list.push(e['id'])
                  })
                  el['user_list'].forEach(e1 => {
                    users_list.push(e1['id'])
                  })
                }
                if (ele['locations'].length == el['location_list'].length) {
                  ele['locations'].forEach(e => {
                    loc_list.push(e['id'])
                  })
                  el['location_list'].forEach(e1 => {
                    loc_data.push(e1['id'])
                  })
                }

              }
            }
          })
        }
        else bool = true;
      })
      // console.log(clinician_list)
      // console.log(users_list)
      let a = [], b = [], c = [], d =[];
      a = clinician_list.sort(function(a, b){return a-b});
      b = users_list.sort(function(a, b){return a-b});
      c = loc_list.sort(function(a, b){return a-b});
      d = loc_data.sort(function(a, b){return a-b});
      let aa, bb, cc, dd;
      aa = a.toString();
      bb = b.toString();
      cc = c.toString();
      dd = d.toString();
      if (aa === bb && cc === dd) {
        bool = true;
      }
      else {
        bool = false;
      }
      if (alertData.length == 0) bool = true;
      // console.log(chkalert, this.checkAlert, listCkh, bool);
      if (chkalert.length == this.checkAlert.length && bool && !listCkh.includes(false) && !locCkh.includes(false) && (settingsData['time_interval_days'] == this.data['general_settings']['time_interval_days'] &&
        settingsData['time_interval_hrs'] == this.data['general_settings']['time_interval_hrs'] &&
        settingsData['time_interval_mins'] == this.data['general_settings']['time_interval_mins'] &&
        settingsData['retry_count'] == this.data['general_settings']['retry_count'])) {
        bool = true
      }
      else {
        bool = false;
      }
      return bool;
    }


    if(this.addSettingsForm!=undefined){
      let bool=false
      let settingsData = this.addSettingsForm.value;
      if(this.is_update==true){
        if(settingsData['gender_dependency']==this.data['update_data']['gender_dependency']){
          bool = true
        } else {
          bool = false;
        }
      } else {
        if(settingsData['gender_dependency']==this.addSettingsForm.controls['gender_dependency']){
          bool = true
        } else {
          bool = false;
        }
      }
      return bool;
    }
    
  }

  closeModal() {
    this.dialogRef.close();

  }

  enableRule(event: MatCheckboxChange, index,rule) {
    if (event.checked) {
      this.rulesList.controls[index].get('is_alert').setValue(true);
      this.rulesList.controls[index].get('is_alert').setValidators([Validators.required]);
      this.rulesList.controls[index].get('clinicians').setValidators([Validators.required]);
    } else {
      let loc_ids = [];
      this.locationList.forEach(e=>{
        loc_ids.push(e.id)
      })
      let loc_data = {
        location_list: loc_ids,
        rule_id: rule.value.id,
        clinic_assessment_id: this.data.clinic_assessment_id,
      }
      this.assessmentService.getNotify(loc_data).subscribe(res=>{
        if(res['data'].length > 0){
          res['data']['assessment_name'] = this.data.assessment_name.value;
          let dialogConfig = new MatDialogConfig();
          dialogConfig.width = '70%';
          dialogConfig.data = {
            alert_list: res
          }
          dialogConfig.position = { top: '70px' };
          dialogConfig.disableClose = true;
          let dialogRef = this.dialog.open(ListDialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(val=>{
            this.rulesList.controls[index].get('is_alert').setValue(true);
          })
        }else{
          this.rulesList.controls[index].get('is_alert').setValue(false);
          this.rulesList.controls[index].get('selectAllClinicians').setValue(false)
          this.rulesList.controls[index].get('clinicians').setValue([]);
          this.rulesList.controls[index].get('clinicians').clearValidators();
          this.rulesList.controls[index].get('clinicians').updateValueAndValidity();
          this.rulesList.controls[index].get('locations').setValue([]);
          this.rulesList.controls[index].get('locations').updateValueAndValidity();
          this.rulesList.controls[index].get('allLocations').setValue(false);
          // this.rulesList.controls[index].get('assigned_clinician').setValue(false);
          this.rulesList.controls[index].get('is_alert').clearValidators();
        }
      })
      
    }
  }

  selectAllWrap(index) {
    this.rulesList.controls[index].get('selectAllClinicians').setValue(!this.rulesList.controls[index].value['selectAllClinicians']);
    this.selectAllClinicians({ checked: this.rulesList.controls[index].value['selectAllClinicians'] }, index);
  }

  changeSelectAll(index) {
    if (this.rulesList.controls[index].value['clinicians'].length == this.clinicianList.length) {
      
      this.rulesList.controls[index].get('selectAllClinicians').setValue(true);
    } else {
      
      this.rulesList.controls[index].get('selectAllClinicians').setValue(false);
    }
  }

  selectLocation(index, id){

    if (this.rulesList.controls[index].value['locations'].length != this.locationList.length) {
      this.rulesList.controls[index].get('allLocations').setValue(false);
    }
  }

  getId(ind,loc,rule){
    let selected = this.rulesList.controls[ind].value['locations'].find(val => val.id == loc);
    if(!selected){
      let loc_data = {
        location_list: [loc.id],
        rule_id: rule.value.id,
        clinic_assessment_id: this.data.clinic_assessment_id,
      }
      this.assessmentService.getNotify(loc_data).subscribe(res=>{
        if(res['data'].length > 0){
          res['data']['assessment_name'] = this.data.assessment_name.value;
          let dialogConfig = new MatDialogConfig();
          dialogConfig.width = '70%';
          dialogConfig.data = {
            alert_list: res
          }
          dialogConfig.position = { top: '70px' };
          dialogConfig.disableClose = true;
          let dialogRef = this.dialog.open(ListDialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(val=>{
            let selec_loc = this.rulesList.controls[ind].value['locations'];
            selec_loc.push(loc);
            this.rulesList.controls[ind].get('locations').setValue(selec_loc);
            if(this.locationList.length == selec_loc.length){
              this.rulesList.controls[ind].get('allLocations').setValue(true);
            }
          })
        }
      })
    }
  }

  selectAllLocation(event: any, index,rule) {
    if (event.checked) {
      this.rulesList.controls[index].get('locations').setValue(this.locationList.concat([]));
    }
    else {
      let loc_ids = [];
      this.locationList.forEach(e=>{
        loc_ids.push(e.id)
      })
      let loc_data = {
        location_list: loc_ids,
        rule_id: rule.value.id,
        clinic_assessment_id: this.data.clinic_assessment_id,
      }
      this.assessmentService.getNotify(loc_data).subscribe(res=>{
        if(res['data'].length > 0){
          res['data']['assessment_name'] = this.data.assessment_name.value;
          let dialogConfig = new MatDialogConfig();
          dialogConfig.width = '70%';
          dialogConfig.data = {
            alert_list: res
          }
          dialogConfig.position = { top: '70px' };
          dialogConfig.disableClose = true;
          let dialogRef = this.dialog.open(ListDialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(val=>{
            let selected_loc = [];
            this.locationList.filter((element:any) => {
              for (let selected of res['data'])
              if (element['clinic_location'] == selected['location']) selected_loc.push(element);
            })
            this.rulesList.controls[index].get('locations').setValue(selected_loc);
            if(selected_loc.length == this.locationList.length){
              this.rulesList.controls[index].get('allLocations').setValue(true);
            }
          })
        }else{
          this.rulesList.controls[index].get('locations').setValue([]);
        }
      })
      
    }
  }


  selectAllClinicians(event: any, index) {
    if (event.checked) {
      this.rulesList.controls[index].get('clinicians').setValue(this.clinicianList.concat([]));
      this.matSelect_j._results[index].options._results.forEach((x,y) => {
        x.select();
      });
    }
    else {
      this.matSelect_j._results[index].options._results.forEach((x,y) => {
        x.deselect();
      });
      this.rulesList.controls[index].get('clinicians').setValue([]);
    }
  }

  saveGeneralSettings() {
    if (!this.checkValueChanges()) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '500px';
      dialogConfig.disableClose = true;
      dialogConfig.data = { 'yes': null, 'no': null };
      let dialogRef = this.dialog.open(ActivateDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        value => {
          if (value == 'yes') {
            this.dialogRef.close()
          }
        }
      );
    }
    else {
      this.dialogRef.close()
    }
  }

  saveAlertForm() {
    let t_alert = this.alertForm.value
    t_alert['rules_list'].forEach(element => {
      let alert_id = element['id']
      if (element['is_alert'] == true) {
        let temp = {
          id: element['id'],
          users: element['clinicians'],
          locations: element['locations'],
          allLocations: element['allLocations'],
          allClinician: element['selectAllClinicians'],
          // assigned_clinician: element['assigned_clinician']
        }
        this.selected_rule.push(temp);
      }
      else if (this.checkAlert.includes(alert_id)) {
        this.deleted_rules.push(alert_id);
      }
    });
    let data = {
      'selected_rule': this.selected_rule,
      'deleted_rules': this.deleted_rules,
      'add_settings_form': this.addSettingsForm.value
    }

    this.assessmentService.createAlertSettings(data, this.data['clinic_assessment_id']).subscribe(response => {
      this.snackBar.triggerSnackBar({
        message: 'Assessment General Settings Updated',
        type: 'success'
      });
      this.dialogRef.close()
      // this.saveGeneralSettings();
    },
      (error: any) => {
        // this.inProgress = false;
        this.snackBar.triggerSnackBar({
          message: 'Failed to update Assessment General Settings',
          type: 'error'
        });
        this.dialogRef.close()
        // this.saveGeneralSettings();
      })
  }

  timeIntervalHrsChange() {
    if (this.addSettingsForm.get('time_interval_hrs').value == 0 && this.addSettingsForm.get('time_interval_mins').value == 0 && this.addSettingsForm.get('time_interval_days').value == 0) {
      this.addSettingsForm.get('time_interval_mins').setValue(15);
    }
  }
  saveGenderDependency(value){
    if (this.is_update == true) {
      this.data.update_data['gender_dependency'] = value.gender_dependency;
    } else {
      this.addSettingsForm.controls['gender_dependency'].setValue(value['gender_dependency'])
    }
    this.dialogRef.close(value)
  }


}
