import { Component, OnInit, Inject } from '@angular/core';
import { AssessmentsService } from 'app/support/services/assessments.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AssessmentGeneralSettingsComponent } from 'app/shared/components/dialog-box/assessment-general-settings/assessment-general-settings.component';
import { ActivateDialogComponent } from 'app/shared/components/dialog-box/activate-dialog/activate-dialog.component';
import { PatientService } from 'app/support/services/patient.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ValidationService } from 'app/shared/services/validation.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-clinic-assessment-activate',
    templateUrl: './clinic-assessment-activate.component.html',
    styleUrls: ['./clinic-assessment-activate.component.scss']
})
export class ClinicAssessmentActivateComponent implements OnInit {

    clinicAssessments: Array<any> = [];
    clinicianList: Array<any> = [];
    assessmentList: FormArray;
    alert_data: FormArray;
    assessmentForm: FormGroup;
    assessmentLoad: boolean = false;
    inProgress: boolean = false;
    showView: boolean = false;
    selectedIndex: number;
    general_settings_data: any = undefined;
    Assessment: boolean = false;
    // changing variable for discard changes
    variableOne: Array<any> = [];
    view = 'Assessment';
    // static variable for discard changes
    variableTwo: any = undefined;
    Assessment_Alerts: boolean = false;
    clinicId: any;
    assessmentAllId: any;
    clinicName: any;
    assignForm: FormGroup;
    alert_navigation: FormGroup;
    alert_navigation_list: FormGroup;
    assessment_alert_data: Object={};
    clinic_alert_data : Array<any> = [];
    assessment_navigation_data: any;
    alert_assessment: boolean;
    alert_navigation_data: FormGroup;
    alert_loader: boolean;
    assessment_index: any;
    assessment_queue_index: any;
    urldisabled: boolean = true;
    changeview: boolean = true;
    changeviewalert: boolean = false;
    queuedisable: boolean = false;
    show_num: number = -1;
    alertassessmentForm: FormGroup;
    alertassessmentqueueForm: FormGroup;
    alertassessmentList: FormArray;
    edit_alert_rule: boolean = false;
    alertassessmentqueueList: FormArray;
    device_type:string= 'Mobile/Desktop';
    assessmentqueueList: Array<any>=[];
    url_data: Object = {};
    queue_data: Object = {};
    url_queue_data: Object = {};
    order_drop: boolean=false;
    button_disable: boolean=false;
    queuechange: boolean=false;
    hide: boolean = false;
    savedisable: boolean;
    generalsettingbtn: boolean;
    locationList: Array<any> = [];
    locationListVal: Array<any> = [];
    locationLen: boolean = false;
    editLocationLen: boolean = false;
    constructor(private assessmentService: AssessmentsService,
        private patientService: PatientService,
        private dialog: MatDialog,
        private snackBar: SnackbarCongiration,
        private fb: FormBuilder
    ) {
        this.Assessment = true;
        this.assignForm = this.fb.group({
            clinic: [null, Validators.required]
        });
        this.alert_navigation = this.fb.group({
            assessment_name: new FormControl('', Validators.required),
            rule_name: new FormControl('', Validators.required),
            locations: new FormControl([]),
            allLocations: new FormControl(false),
            url: new FormControl('', [Validators.required, ValidationService.urlvalidatorstr]),
            device_type: new FormControl('',[Validators.required])
        });
        // this.alert_navigation_list = this.fb.group({
        //     assessment_name: new FormControl(''),
        //     alert_rule_name : new FormControl(''),
        //     url : new FormControl('')
        // });
        this.alert_navigation_data = this.fb.group({
            alert_data: this.fb.array([])
        });
        this.getInitialAssessment();
        
    }

    ngOnInit() {

    }

    getInitialAssessment(){
        this.assessmentService.getClinicAssessments().subscribe(
            (response: any) => {
                this.general_settings_data = response['data']['general_settings_data'];
                this.clinicAssessments = response['data']['clinic_data'].concat([]);
                this.variableOne = JSON.parse(JSON.stringify(this.clinicAssessments));
                this.assignForm.get('clinic').setValue(this.clinicAssessments[0]['clinic_id']);
                this.variableTwo = JSON.stringify(response['data']['clinic_data'].concat([]));
                this.createAssessmentForm(0, this.clinicAssessments[0]['clinic_id']);
                this.showView = true;
            }
        );
    }

    createAssessmentForm(index: any, data) {
        this.locationList = [];
        this.locationListVal = [];
        this.assessmentLoad = false;
        this.selectedIndex = index;
        this.clinicAssessments.forEach((ele, ind) => {
            if (ele.clinic_id == data) {
                this.selectedIndex = ind;
            }
        })
        let clinic = new Object(this.clinicAssessments[this.selectedIndex]);
        // let clinic = data;
        let clinic_Id = data
        this.clinicId = clinic;
        this.assessmentForm = this.fb.group({});
        this.assessmentForm = this.fb.group({
            clinic_id: clinic,
            assessment_list: this.fb.array([])
        });

        // let clinic_id = {
        //     clinic_id: this.clinicId

        // }
        this.assessmentList = this.assessmentForm.get('assessment_list') as FormArray;
        if (this.clinicId) {
            if (clinic['assessments'].length) {
                clinic['assessments'].forEach(asEl => {
                    // console.log(asEl)
                    this.assessmentList.push(this.fb.group({
                        id: asEl['id'],
                        assessment_id: asEl['assessment_id'],
                        assessment__assessment_name: asEl['assessment__assessment_name'],
                        clinic_active: asEl['clinic_active'],
                        insurer_billable: { value: asEl['insurer_billable'], disabled: !asEl['clinic_active'] },
                        general_settings: asEl['general_settings']
                    }));
                });
                // this.getAlertUrlQueue();
                // this.getAlertAssessment();
            }
        }
        
        if(this.view == 'Assessment_Alerts')
            this.alertassessmentForm.reset();
            this.alert_navigation.reset();
            this.getAlertAssessment();
            this.getAlertUrlQueue();
            this.alert_cancel("","");
        this.assessmentLoad = true;
    }
    changeView(name) {
        if (name == 'Assessment') {
            // this.alert_order();
            this.view = name;
            this.Assessment = true;
            this.changeview = true;
            this.changeviewalert = false;
            this.locationList = this.locationListVal.concat([]);
            
        }
        else if (name == 'Assessment_Alerts') {
            this.Assessment_Alerts = true;
            this.changeviewalert = true;
            this.changeview = false;
            this.view = name;
            this.alert_navigation_data.removeControl('alert_data')
            this.getAlertAssessment();
            this.getAlertUrlQueue();
            this.alertassessmentForm.reset();
            this.alert_navigation.reset();
            this.alert_cancel("","");
            this.alert_navigation.controls['rule_name'].disable();
            this.alert_navigation.controls['url'].disable();
            this.alert_navigation.get('device_type').disable();
            this.alert_navigation.controls['locations'].disable();


        }
    }

    getAlertAssessment(){
        this.alertassessmentForm = this.fb.group({
            clinic_id: this.clinicId,
            assessment_list: this.fb.array([])
        });
        this.alertassessmentqueueForm = this.fb.group({
            clinic_id: this.clinicId,
            assessment_list: this.fb.array([])
        });
        let clinic_id = { clinic_id: this.clinicId['clinic_id'] }
        this.alertassessmentList = this.alertassessmentForm.get('assessment_list') as FormArray;
        this.alertassessmentqueueList = this.alertassessmentqueueForm.get('assessment_list') as FormArray;
        this.assessmentService.getAlertAssessment(clinic_id).subscribe(
            (response: any) => {
                this.assessmentqueueList = [];
                let res_data = response['data']['clinic_data'];
                let temp = [];
                if(response['data']['clinic_location'][0]['city'] != null){
                    response['data']['clinic_location'].forEach(e => {
                        temp.push({id:e['id'],clinic_location:e['clinic_location']})
                    })
                    this.locationList = temp;
                    this.locationListVal = temp;
                }
                res_data['assessments'].forEach(asEl => {
                    // console.log(asEl)
                    this.alertassessmentList.push(this.fb.group({
                        id: asEl['id'],
                        assessment_id: asEl['assessment_id'],
                        assessment__assessment_name: asEl['assessment__assessment_name'],
                        clinic_active: asEl['clinic_active'],
                    }));
                    this.alertassessmentqueueList.push(this.fb.group({
                        id: asEl['id'],
                        assessment_id: asEl['assessment_id'],
                        assessment__assessment_name: asEl['assessment__assessment_name'],
                        clinic_active: asEl['clinic_active'],
                    }));
                    this.assessmentqueueList.push(asEl['assessment__assessment_name']);

                });
            }
        );
    }

    // enable checkbox
    enableState(event: MatCheckboxChange, index: any) {
        this.variableOne[this.selectedIndex]['assessments'][index]['clinic_active'] = event.checked;
        if (event.checked)
            this.assessmentList.controls[index]['controls']['insurer_billable'].enable()
        else {
            this.assessmentList.controls[index].get('insurer_billable').setValue(false);
            this.assessmentList.controls[index]['controls']['insurer_billable'].disable();
        }
    }

    // billable checkbox
    billableState(event: MatCheckboxChange, index: any) {
        this.variableOne[this.selectedIndex]['assessments'][index]['insurer_billable'] = event.checked;
    }

    configureAssessmentSettings(i, assessment) {
        this.generalsettingbtn = true;
        this.assessmentList = this.assessmentForm.get('assessment_list') as FormArray;
        let general_settings = this.assessmentList.controls[i].get('general_settings')
        let clinic_assess = this.clinicAssessments[this.selectedIndex].assessments[i].id;
        let clinic_id = this.clinicId['clinic_id']
        this.assessmentService.getClinicUsers(clinic_id).subscribe(data => {
            this.clinicianList = data['clinicians'];
            this.assessmentService.getAssessmentAlert(clinic_assess, assessment).subscribe((response: any) => {
                this.alert_data = response['data'];
                let dialogConfig = new MatDialogConfig();
                dialogConfig.width = '1160px';
                dialogConfig.disableClose = true;
                dialogConfig.restoreFocus = false;
                let data = {
                    'clinic_assessment_id': clinic_assess,
                    'type': 'clinic',
                    'general_settings_data': this.general_settings_data,
                    'general_settings': response['data']['general_settings'],
                    'alert_data': this.alert_data,
                    'clinicians': this.clinicianList,
                    'locations': this.locationList,
                    'assessment_name': this.assessmentList.controls[i].get('assessment__assessment_name')
                }
                if (Object.keys(general_settings.value).length > 0) {
                    data['update_data'] = general_settings.value;
                }
                dialogConfig.data = data
                const dialogRef = this.dialog.open(AssessmentGeneralSettingsComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(
                    value => {
                        if (value) {
                            this.generalsettingbtn = false;
                            // this.mainModaldata = value;
                            // this.mainModaldata['clinicAssessmentId'] = value['clinic_data']['clinic_assessment_id']
                            general_settings.setValue(value);
                            // this.variableOne[this.selectedIndex]['assessments'][i]['general_settings'] = value;
                        }
                    })
                    this.generalsettingbtn = false;
            })
        })
    }

    save() {
        // let dialogConfig = new MatDialogConfig();
        // dialogConfig.position = { top: '70px' };
        // dialogConfig.width = '400px';
        // dialogConfig.disableClose = true;
        // dialogConfig.restoreFocus = false;
        // dialogConfig.data = {
        //     form: this.assessmentForm,
        //     clinicAssessments: this.clinicAssessments,
        //     selectedIndex: this.selectedIndex,
        // }
        // let dialogRef = this.dialog.open(ActivationConfirmation, dialogConfig);
        let newAssessment = this.saveAssessments();
        let clinic_id = this.assessmentForm.value['clinic_id']['clinic_id']
        if (newAssessment['add'].length || newAssessment['change'].length || newAssessment['remove'].length || newAssessment['general_settings'].length) {
            this.assessmentService.updateClinicAssessments({ assessments: newAssessment, clinic_id: clinic_id}).subscribe(
                (response: any) => {
                    this.clinicAssessments[this.selectedIndex]['assessments'] = response['data'].concat([]);
                    this.variableOne[this.selectedIndex]['assessments'] = response['data'];
                    let actualVal = JSON.parse(this.variableTwo);
                    actualVal[this.selectedIndex]['assessments'] = response['data'];
                    this.variableTwo = JSON.stringify(actualVal);
                    // this.inProgress = false;
                    this.snackBar.triggerSnackBar({
                        message: 'Clinic assessment updated',
                        type: 'success'
                    });
                    this.getInitialAssessment();
                },
                (error: any) => {
                    // this.inProgress = false;
                    this.snackBar.triggerSnackBar({
                        message: 'Failed to update assessments',
                        type: 'error'
                    });
                }
            );
        }
        // else {
        //     // this.inProgress = false;
        //     this.snackBar.triggerSnackBar({
        //         message: 'Clinic assessment updated',
        //         type: 'success'
        //     });
        // }

    }


    saveAssessments() {
        let newAssessment = { add: [], remove: [], change: [], general_settings: [] };
        let assessment = this.assessmentForm.value;
        // this.inProgress = true;

        let clinicAssessmentTemp = this.clinicAssessments[this.selectedIndex]['assessments'].concat([]);
        let assessmentTemp = assessment['assessment_list'].concat([]);

        // assessment change detection
        assessment['assessment_list'].forEach(element => {
            for (let clinic of this.clinicAssessments[this.selectedIndex]['assessments']) {
                if (element['id'] == clinic['id']) {
                    if (this.checkGeneralSettingsChange(element, clinic)) {
                        newAssessment['general_settings'].push({ 'clinic_assessment_id': element['id'], general_settings: element['general_settings'] })
                    }
                    if (element['clinic_active'] && clinic['clinic_active']) {

                        if (element['insurer_billable'] != clinic['insurer_billable']) {
                            let index = clinicAssessmentTemp.indexOf(clinic);
                            clinicAssessmentTemp[index]['insurer_billable'] = !clinicAssessmentTemp[index]['insurer_billable'];
                            newAssessment['change'].push(clinicAssessmentTemp.splice(index, 1)[0]);
                            assessmentTemp.splice(assessmentTemp.indexOf(element), 1);
                        }
                        else {
                            clinicAssessmentTemp.splice(clinicAssessmentTemp.indexOf(clinic), 1);
                            assessmentTemp.splice(assessmentTemp.indexOf(element), 1);
                        }
                    }
                    else if (element['clinic_active'] && !clinic['clinic_active']) {
                        newAssessment['add'].push(assessmentTemp.splice(assessmentTemp.indexOf(element), 1)[0]);
                        clinicAssessmentTemp.splice(clinicAssessmentTemp.indexOf(clinic), 1);
                    }
                    else if (!element['clinic_active'] && clinic['clinic_active']) {
                        newAssessment['remove'].push(assessmentTemp.splice(assessmentTemp.indexOf(element), 1)[0]);
                        clinicAssessmentTemp.splice(clinicAssessmentTemp.indexOf(clinic), 1);
                    }
                    else {
                        clinicAssessmentTemp.splice(clinicAssessmentTemp.indexOf(clinic), 1);
                        assessmentTemp.splice(assessmentTemp.indexOf(element), 1);
                    }
                }
            }
        });
        return newAssessment;
        // api based on changes made
        // if (newAssessment['add'].length || newAssessment['change'].length || newAssessment['remove'].length || newAssessment['general_settings'].length) {
        //     this.assessmentService.updateClinicAssessments({ assessments: newAssessment, clinic_id: this.assessmentForm.value['clinic_id'] }).subscribe(
        //         (response: any) => {
        //             this.clinicAssessments[this.selectedIndex]['assessments'] = response['data'].concat([]);
        //             // this.inProgress = false;
        //             this.snackBar.triggerSnackBar({
        //                 message: 'Clinic assessment updated',
        //                 type: 'success'
        //             });
        //         },
        //         (error: any) => {
        //             // this.inProgress = false;
        //             this.snackBar.triggerSnackBar({
        //                 message: 'Failed to update assessments',
        //                 type: 'error'
        //             });
        //         }
        //     );
        // }
        // else {
        //     // this.inProgress = false;
        //     this.snackBar.triggerSnackBar({
        //         message: 'Clinic assessment updated',
        //         type: 'success'
        //     });
        // }
    }
    confirm(): Promise<any> {
        return new Promise((resolve) => {
            // let newAssessment = this.saveAssessments();
            if (JSON.stringify(this.variableOne) != this.variableTwo && this.showView) {
                const dialogConfig = new MatDialogConfig();
                // dialogConfig.position = { top: '70px' };
                dialogConfig.width = '500px';
                dialogConfig.disableClose = true;
                dialogConfig.data = { 'yes': null, 'no': null };
                let dialogRef = this.dialog.open(ActivateDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(
                    value => {
                        if (value == 'yes') {
                            this.dialog.closeAll();
                            resolve(true);
                        } else {
                            this.dialog.closeAll();
                            resolve(false);
                        }
                    }
                );
            } 
            else if (this.order_drop && !this.button_disable) {
                const dialogConfig = new MatDialogConfig();
                // dialogConfig.position = { top: '70px' };
                dialogConfig.width = '500px';
                dialogConfig.disableClose = true;
                dialogConfig.data = { 'yes': null, 'no': null };
                let dialogRef = this.dialog.open(AlertOrderConfirmationComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(
                    value => {
                        if (value == 'yes') {
                            this.dialog.closeAll();
                            this.saveAlertOrder();
                            resolve(true);
                        } else {
                            this.dialog.closeAll();
                            resolve(false);
                        }
                    }
                );
                // this.alert_order();
            }
            else {
                resolve(true);
            }
        })
    }

    checkGeneralSettingsChange(element, clinic) {
        if (element['general_settings']['time_interval_hrs'] == clinic['general_settings']['time_interval_hrs']) {
            if (element['general_settings']['time_interval_days'] == clinic['general_settings']['time_interval_days']) {
                if (element['general_settings']['time_interval_mins'] == clinic['general_settings']['time_interval_mins']) {
                    if (element['general_settings']['retry_count'] == clinic['general_settings']['retry_count']) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    /*************************************************************************** */
    //all below codes are associated with alert queue creation related functions
    /*************************************************************************** */


    /**
     * 
     * @param value reset or disable
     * this method is used to reset or  the alert navigation form
     * based on the user input when calling this function
     */
    alertFormResetDisable(value:String){
        this.locationLen = false;
        if (value.toLowerCase() == "reset"){
            this.alert_navigation.get('url').reset();
            this.alert_navigation.get('rule_name').reset();
            this.alert_navigation.get('device_type').reset();
            this.alert_navigation.get('locations').reset();
            this.alert_navigation.get('allLocations').reset();
        }
        else if(value.toLowerCase() == "disable"){
            this.alert_navigation.get('rule_name').disable();
            this.alert_navigation.get('device_type').disable();
            this.alert_navigation.get('url').disable();
            this.alert_navigation.get('locations').disable();
            this.alert_navigation.get('allLocations').disable();
        }
        else{
            this.alert_navigation.get('rule_name').enable();
            this.alert_navigation.get('device_type').enable();
            this.alert_navigation.get('url').enable();
            this.alert_navigation.get('locations').enable();
            this.alert_navigation.get('allLocations').enable();
        }
    }

    /**
     * 
     * @param event assessment data
     * @param i index from the list of assessment
     * This method is used to retrive the rule names
     * associated with a the assessment name only retrive
     * assessments that are enabled in the assessment settings
     * and also post navigation in the MHT Level
     */
    getAssessmentRules(event, i) {
        this.assessment_alert_data = {};
        this.locationLen = false;
        this.alert_navigation.get('url').disable();
        this.alert_navigation.get('device_type').disable();
        this.alert_navigation.get('locations').disable();
        this.alert_navigation.get('allLocations').disable();
        this.alert_navigation.get('rule_name').enable();
        // let assessment_detail = this.assessmentList.value[i];
        // let alert_data = {
        //     assessment_id: assessment_detail['assessment_id'],
        //     clinic_assessment_id: assessment_detail['id'],
        //     clinic_id: this.clinicId['clinic_id'] 
        // }
        let alert_data;
        this.assessmentList.value.every(ass=>{
            if(event == ass['assessment__assessment_name'])
            {
                alert_data = {assessment_id: ass['assessment_id'],
                    clinic_assessment_id: ass['id'],
                    clinic_id: this.clinicId['clinic_id']
                };
                return false;
            }
            return true;

            
        })

        this.assessment_index = i;
        // let alert_data = {assessment_id: assessment,
        //     clinic_assessment_id: clinic_assess,
        //     clinic_id: this.clinicId['clinic_id']
        // };
        this.assessmentService.getActiveAssessmentAlert(alert_data).subscribe((response: any) => {
            this.assessment_alert_data = response['data'].alert_rule;
            this.clinic_alert_data = response['data'].clinic_alerts;
        })
        this.alertFormResetDisable("reset");
    }

    /**
     * 
     * @param event rule data
     * getting the rule data from the dropdown
     * and store it in the variable url_data
     * later to use this to getting the urls from
     * backend
     */
    getRuleUrlData(event){
        this.locationList = [];
        this.assessmentList.value.forEach(e =>{
            if(event.value['assessment_id'] == e['assessment_id']) event.value['clinic_assessment'] = e['id'];
        })
        this.url_data= {
            alert_rule: event.value['id'],
            clinic_id: this.clinicId['clinic_id'],
            device_type: this.device_type,
            clinic_assessment: event.value['clinic_assessment']
        }
        this.clinic_alert_data.forEach(e=>{
            if(e['alert_rule'] == event.value['id']){
                this.locationList = e['location_list'];
            }
        })
        this.locationLen = this.locationList.length == 0 ? true : false;
        this.alert_navigation.get('locations').enable();
        this.alert_navigation.get('device_type').enable();
        this.alert_navigation.get('url').enable();
        this.alert_navigation.get('device_type').setValue(this.device_type)
        this.assessmentService.getAlertLocation(this.url_data).subscribe((res: any) => {
            this.locationList = this.locationList.map(val => {
                const assigned = res['data']['unassigned_location'].find(value => value.id == val.id);
                if(assigned){
                    val['disabled'] = true;
                }
                return val;
            })
            if(res['data']['assigned_location'] == true && res['data']['unassigned_location'].length == 0){
                this.alert_navigation.get('allLocations').enable();
            }else this.alert_navigation.get('allLocations').disable();
        })
        // this.assessmentService.getAlertUrl(this.url_data).subscribe((response: any) => {
        //     if (response.status == 'success') {
        //         this.alert_navigation.get('url').setValue(response.data);
        //     }
        //     else{
        //         this.alert_navigation.get('url').setValue("");
        //     }

        // })

    }

    selectAllLocation(event,i,list){
        let alertarray = this.alert_navigation_data.get('alert_data');
        if(event.checked){
            if(i != undefined){
                alertarray['controls'][i].get('locations').setValue(list.concat([]));
            }else{
                this.alert_navigation.get('locations').setValue(this.locationList.concat([]))
            }
        }else{
            if(i != undefined){
                alertarray['controls'][i].get('locations').setValue([]);
            }else{
                this.alert_navigation.get('locations').setValue([]);
            }
        }
    }

    selectLocation(evt,i,list){
        let alertarray = this.alert_navigation_data.get('alert_data');
        if(i != undefined){
            if(alertarray['controls'][i].value['locations'].length != list.length){
                alertarray['controls'][i].get('allLocations').setValue(false);
            }
        }else{
            if (this.alert_navigation.get('locations').value.length != this.locationList.length) {
                this.alert_navigation.get('allLocations').setValue(false);
            }
        }
      }


    /*
    this method is used to create a alert based on the
    user input in the alert_navigation form. Once form
    create it's also refresh the alert queue and reset the
    alert_navigation form.
    */
    create_alert_priority(formDirective: FormGroupDirective) {
        this.locationLen = false;
        let clinic_ass_id = null;
        //taking clinic assessment id from total assessment in clinic level
        this.clinicId['assessments'].every(ele=>{
            if(ele['assessment__assessment_name'] == this.alert_navigation.value['assessment_name']){
                clinic_ass_id=ele['id'];
                return false;
            }
            return true;
        })
        //gathering data for create a alert
        let data = {
            assessment_id: this.alert_navigation.value['rule_name'].assessment_id,
            clinic_assessment_id: clinic_ass_id,
            assessment_name: this.alert_navigation.value['assessment_name'],
            rule: this.alert_navigation.value['rule_name'],
            url: this.alert_navigation.value['url'],
            clinic_id: this.clinicId['clinic_id'],
            device_type: this.alert_navigation.value['device_type'],
            locations: this.alert_navigation.value['locations'],
            allLocations: this.alert_navigation.value['allLocations'] != null ? this.alert_navigation.value['allLocations'] : false
        }

        //making a post call to create a alert data
        this.assessmentService.createAlertNavigation(data).subscribe((response: any) => {
            if (response['status'] == 'success') {
                this.snackBar.triggerSnackBar({
                    message: 'Alert Navigation added successfully',
                    type: 'success'
                });
                //refersh the alert queue
                this.getAlertUrlQueue();
                this.getAlertAssessment();
                //reset the alert from
                formDirective.resetForm();
                this.alert_navigation.reset();
                this.alert_navigation.clearValidators();
                this.alertFormResetDisable("disable");
                this.order_drop = true;
            }
            else {
                this.snackBar.triggerSnackBar({
                    message: response['message'],
                    type: 'error'
                });
               this.getInitialAssessment();
                formDirective.resetForm();
                this.alert_navigation.reset();
                this.alert_navigation.clearValidators();
                this.alertFormResetDisable("disable");
            }
            (error: any) => {
                // this.inProgress = false;
                this.snackBar.triggerSnackBar({
                    message: 'Failed to create Alert Navigation',
                    type: 'error'
                });
                this.getAlertUrlQueue();
                this.getAlertAssessment();
                formDirective.resetForm();
                this.alert_navigation.reset();
                this.alert_navigation.clearValidators();
                this.alertFormResetDisable("disable");
            }

        })
    }
    
    /*************************************************************** */
    //all below codes are associated with alert queue related functions
    /*************************************************************** */

    /**
     * 
     * @param event 
     * this method is used to rearrange the array index on the drag and drop function
     */
    alertdrop(event: CdkDragDrop<Object[]>) {
        moveItemInArray(this.alert_navigation_data.controls['alert_data']['controls'], event.previousIndex, event.currentIndex);
        moveItemInArray(this.alert_navigation_data.controls['alert_data'].value, event.previousIndex, event.currentIndex);
        this.order_drop = true;
    }

    /**
     * 
     * @param index assessment index
     * This method is used to reset the alert_navigation_data
     * form url and device type based on the assessment index
     */                    
    alertqueueFormReset(index) {
        let alertarray = this.alert_navigation_data.get('alert_data');
        alertarray['controls'][index].get('url').reset();
        alertarray['controls'][index].get('device_type').reset();
        this.locationLen = false;
    }

    /**
     * retriving all the assessment alert related data associated with
     * assessment name,rule name, device type and url to show
     * in the queue
     */
    getAlertUrlQueue() {
        this.alert_assessment = false;
        this.alert_loader = false;
        let clinic_ID = this.clinicId['clinic_id']
        this.alert_navigation_data = this.fb.group({
            alert_data: this.fb.array([])
        });
        let alert_data = this.alert_navigation_data.get('alert_data') as FormArray;
        this.assessmentService.getAlertNavigation(clinic_ID).subscribe((response: any) => {
            let response_data = response['data'];
            if (response_data instanceof Object) {
                response_data.forEach((ele,index) => {
                    let selected_loc = [];
                    ele['alert_locations'].filter((element:any) => {
                        for (let selected of ele['locations'])
                        if (element['id'] == selected['id']) selected_loc.push(element);
                    })
                    alert_data.push(this.fb.group({
                        id: ele['id'],
                        assessment_name: [ele['clinic_assessment__assessment__assessment_name'], [Validators.required]],
                        rule_name: [ele['rule__rule_name'], [Validators.required]],
                        url: [ele['url'], [Validators.required, ValidationService.urlvalidatorstr]],
                        clinic_assessment_id: ele['clinic_assessment_id'],
                        assessment_id: ele['clinic_assessment__assessment_id'],
                        device_type: ele['device_type'],
                        rule_id: ele['rule_id'],
                        locations: [selected_loc],
                        allLocations: [ele['assigned_location']],
                        alertAssigned:[ele['alert_assigned_location']],
                        alert_locations: [ele['alert_locations']],
                        priority_id: [ele['priority_alert_navigation__id']]
                    }));
                })
                this.alert_loader = true;

            }
            else{
                this.alert_loader = false;
            }
            this.button_disable = this.alert_navigation_data.controls.alert_data['controls'].length == 0 ? true : false;
        })
        
        // this.alert_loader = true;
        this.alert_assessment = true;

    }

    /************************************************************************************* */
    //all below codes are associated with alert queue edit, save and drag related functions
    /************************************************************************************* */

    /**
     * 
     * @param i alert queue index
     * @param event alert queue value
     * This method is used to edit the value in alert queue
     * It enables the particular queue data to edit
     */
    alert_edit(i, event) {
        this.locationLen = false;
        let alertarray = this.alert_navigation_data.get('alert_data');
        this.queuechange = true;
        this.queuedisable = true;
        this.hide = true;
        alertarray.value.forEach((el,ind)=>{
            if(el['rule_name'] == event.value['rule_name'] && el['assessment_name'] == event.value['assessment_name'] && el['id'] != event.value['id']){
                if(el['locations'].length > 0){
                    alertarray['controls'][i].get('allLocations').disable();
                }
                alertarray.value[i]['alert_locations'] = alertarray.value[i]['alert_locations'].map(val => {
                    const assigned = el['locations'].find(value => value.id == val.id);
                    if(assigned){
                        val['disabled'] = true;
                    }
                    return val;
                })
            }
        })
        if(alertarray.value[i]['alert_locations'].length == 0){
            this.editLocationLen = true;
            this.snackBar.triggerSnackBar({
                message: 'No Location mapped with alert',
                type: 'warning'
            });
        }
        if(event.value['alertAssigned'] == false ) alertarray['controls'][i].get('allLocations').disable();
        this.alert_navigation.reset();
        this.alert_navigation.disable();
        this.show_num = i;
        this.assessment_queue_index = i;
        this.edit_alert_rule = true;
    }
    
    /**
     * 
     * @param i alert data queue index
     * @param event alert queue value
     * This method is used to save the alert queue value
     * based on the changes in the alert queue data
     */
    alert_save(i, event) {
        this.editLocationLen = false;
        this.alert_navigation.get('assessment_name').enable();
        this.alertFormResetDisable("disable");
        this.queuechange = false;
        this.hide = false;
        this.show_num = null;
        this.queuedisable = false;
        let post_data = event.value;
        let rule = null;
        // post_data['alert_rules'].forEach(ele => {
        //     if (ele['rule_name'] == post_data['rule_name']) {
        //         rule = ele;
        //     }
        // });
        //this.clinicId['assessments'][this.assessment_queue_index]['id']
        let data = {
            id: post_data.id,
            clinic_assessment_id: post_data['clinic_assessment_id'],
            assessment_name: post_data['assessment_name'],
            rule_id: post_data['rule_id'],
            url: post_data['url'],
            clinic_id: this.clinicId['clinic_id'],
            device_type: post_data['device_type'],
            locations: post_data['locations'],
            allLocations: post_data['allLocations']

        }
        this.assessmentService.saveAlertNavigation(data, data['id']).subscribe((response: any) => {
            if (response['status'] == 'success') {
                this.snackBar.triggerSnackBar({
                    message: 'Alert Navigation updated successfully',
                    type: 'success'
                });
                this.getAlertAssessment();
                this.getAlertUrlQueue();

            }
            else {
                this.snackBar.triggerSnackBar({
                    message: response['message'],
                    type: 'error'
                });
                this.getAlertAssessment();
                this.getAlertUrlQueue();
            }
            (error: any) => {
                // this.inProgress = false;
                this.snackBar.triggerSnackBar({
                    message: 'Failed to save Alert Navigation',
                    type: 'error'
                });
                this.getAlertAssessment();
                this.getAlertUrlQueue();
            }
        })
    }

    /**
     * 
     * @param event 
     * @param i 
     * reset the form
     */
    alert_cancel(event,i){
        this.editLocationLen = false;
        this.show_num = null;
        this.queuechange = false;
        this.hide = false;
        this.queuedisable = false;
        this.alert_navigation.get('assessment_name').enable();
        this.alertFormResetDisable("disable");
        this.getAlertAssessment();
        this.getAlertUrlQueue();
    }
    /**
     * 
     * @param i 
     * @param event 
     * This method is used to delete the alert queue data
     * based on the index value
     */
    alert_delete(i, event) {
        this.alert_navigation.reset();
        this.editLocationLen = false;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '650px';
        dialogConfig.disableClose = true;
        dialogConfig.data = {"yes":null,"no":null};
        let dialogRef = this.dialog.open(AlertDeleteConfirmationComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            value => {
                if (value == 'yes') {
                    // let delete_alert = this.alert_navigation_data.controls.alert_data['controls'][i].value;
                    let delete_alert = event.value;
                    this.alert_navigation_data.controls.alert_data.value.splice(i,1);
                    let alert_data = this.alert_navigation_data.controls.alert_data.value
                    let data = {
                        clinic_id: this.clinicId['clinic_id'],
                        alert_data: alert_data,
                        delete_alert: delete_alert
                    }
                    this.dialog.closeAll();
                    this.assessmentService.DeleteAlertUrlObject(data).subscribe((response: any) => {
                        if (response['status'] == 'success') {
                            this.snackBar.triggerSnackBar({
                                message: 'Alert Priority Deleted Successfully',
                                type: 'success'
                            });
                            this.getAlertAssessment();
                            this.getAlertUrlQueue();
                        }
                        else {
                            this.snackBar.triggerSnackBar({
                                message: response['message'],
                                type: 'error'
                            });
                            this.getAlertUrlQueue();
                            this.getAlertAssessment();
                        }
                        (error: any) => {
                            // this.inProgress = false;
                            this.snackBar.triggerSnackBar({
                                message: 'Failed to Delete Alert Priority',
                                type: 'error'
                            });
                            this.getAlertUrlQueue();
                            this.getAlertAssessment();
                        }
                    })
                    
                } else {
                    this.dialog.closeAll();
                    
                }
            }
        );
        // this.getAlertUrlQueue();
    }

    // alert_order(){
    //     if (this.order_drop) {
    //         const dialogConfig = new MatDialogConfig();
    //         // dialogConfig.position = { top: '70px' };
    //         dialogConfig.width = '650px';
    //         dialogConfig.disableClose = true;
    //         dialogConfig.data = { 'yes': null, 'no': null };
    //         let dialogRef = this.dialog.open(AlertOrderConfirmationComponent, dialogConfig);
    //         dialogRef.afterClosed().subscribe(
    //             value => {
    //                 if (value == 'yes') {
    //                     this.dialog.closeAll();
    //                     this.saveAlertOrder();
    //                 } else {
    //                     this.dialog.closeAll();
    //                 }
    //             }
    //         );
    //     }
        
    // }
    /**
     * This method is used to save the alert priority order
     * based on the arragement in the queue
     */
    saveAlertOrder() {
        this.savedisable = true;
        let alert_data = this.alert_navigation_data.controls['alert_data'].value;
        // let alert_data = [];
        // alert.forEach(ele=>{
        //     alert_data.push(ele.value);
        // })
        // alert_data.forEach(ele=>{
        //     ele['alert_rules'].forEach(val=>{
        //         if(ele['rule_name'] == val['rule_name'])
        //             ele['rule_id'] = val['id']
        //     })
            
        // })
        let data = {
            clinic_id: this.clinicId['clinic_id'],
            alert_data: alert_data
        }
        this.assessmentService.setAlertPriority(data).subscribe((response: any) => {
            if (response['status'] == 'success') {
                this.snackBar.triggerSnackBar({
                    message: 'Alert Priority Added Successfully',
                    type: 'success'
                });
                this.savedisable = false;
                this.order_drop = false;
                this.getAlertUrlQueue();
                this.getAlertAssessment();
            }
            else {
                this.snackBar.triggerSnackBar({
                    message: response['message'],
                    type: 'error'
                });
                this.savedisable = false;
                this.getAlertUrlQueue();
                this.getAlertAssessment();
            }
            (error: any) => {
                // this.inProgress = false;
                this.snackBar.triggerSnackBar({
                    message: 'Failed to Set Alert Priority',
                    type: 'error'
                });
                this.savedisable = false;
                this.getAlertUrlQueue();
                this.getAlertAssessment();
            }
        })
    }

}


@Component({
    selector: 'activation-confirmation-modal',
    templateUrl: './activation-confirmation-modal.html',
})

export class ActivationConfirmation {
    assessmentForm: FormGroup;
    clinicAssessments: Array<any> = [];
    selectedIndex: number;
    constructor(
        private assessmentService: AssessmentsService,
        private snackBar: SnackbarCongiration,
        public dialogRef: MatDialogRef<ActivationConfirmation>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.assessmentForm = data['form'];
        this.clinicAssessments = data['clinicAssessments']
        this.selectedIndex = data['selectedIndex']
    }

    saveAssessments() {
        let newAssessment = { add: [], remove: [], change: [], general_settings: [] };
        let assessment = this.assessmentForm.value;
        // this.inProgress = true;

        let clinicAssessmentTemp = this.clinicAssessments[this.selectedIndex]['assessments'].concat([]);
        let assessmentTemp = assessment['assessment_list'].concat([]);

        // assessment change detection
        assessment['assessment_list'].forEach(element => {
            for (let clinic of this.clinicAssessments[this.selectedIndex]['assessments']) {
                if (element['id'] == clinic['id']) {
                    if (this.checkGeneralSettingsChange(element, clinic)) {
                        newAssessment['general_settings'].push({ 'clinic_assessment_id': element['id'], general_settings: element['general_settings'] })
                    }
                    if (element['clinic_active'] && clinic['clinic_active']) {
                        if (element['insurer_billable'] != clinic['insurer_billable']) {
                            let index = clinicAssessmentTemp.indexOf(clinic);
                            clinicAssessmentTemp[index]['insurer_billable'] = !clinicAssessmentTemp[index]['insurer_billable'];
                            newAssessment['change'].push(clinicAssessmentTemp.splice(index, 1)[0]);
                            assessmentTemp.splice(assessmentTemp.indexOf(element), 1);
                        }
                        else {
                            clinicAssessmentTemp.splice(clinicAssessmentTemp.indexOf(clinic), 1);
                            assessmentTemp.splice(assessmentTemp.indexOf(element), 1);
                        }
                    }
                    else if (element['clinic_active'] && !clinic['clinic_active']) {
                        newAssessment['add'].push(assessmentTemp.splice(assessmentTemp.indexOf(element), 1)[0]);
                        clinicAssessmentTemp.splice(clinicAssessmentTemp.indexOf(clinic), 1);
                    }
                    else if (!element['clinic_active'] && clinic['clinic_active']) {
                        newAssessment['remove'].push(assessmentTemp.splice(assessmentTemp.indexOf(element), 1)[0]);
                        clinicAssessmentTemp.splice(clinicAssessmentTemp.indexOf(clinic), 1);
                    }
                    else {
                        clinicAssessmentTemp.splice(clinicAssessmentTemp.indexOf(clinic), 1);
                        assessmentTemp.splice(assessmentTemp.indexOf(element), 1);
                    }
                }
            }
        });
        // api based on changes made
        if (newAssessment['add'].length || newAssessment['change'].length || newAssessment['remove'].length || newAssessment['general_settings'].length) {
            this.assessmentService.updateClinicAssessments({ assessments: newAssessment, clinic_id: this.assessmentForm.value['clinic_id'] }).subscribe(
                (response: any) => {
                    this.clinicAssessments[this.selectedIndex]['assessments'] = response['data'].concat([]);
                    // this.inProgress = false;
                    this.snackBar.triggerSnackBar({
                        message: 'Clinic assessment updated',
                        type: 'success'
                    });
                },
                (error: any) => {
                    // this.inProgress = false;
                    this.snackBar.triggerSnackBar({
                        message: 'Failed to update assessments',
                        type: 'error'
                    });
                }
            );
        }
        else {
            // this.inProgress = false;
            this.snackBar.triggerSnackBar({
                message: 'Clinic assessment updated',
                type: 'success'
            });
        }
        this.dialogRef.close();
    }

    checkGeneralSettingsChange(element, clinic) {
        if (element['general_settings']['time_duration_days'] == clinic['general_settings']['time_duration_days']) {
            if (element['general_settings']['time_duration_hrs'] == clinic['general_settings']['time_duration_hrs']) {
                if (element['general_settings']['time_duration_mins'] == clinic['general_settings']['time_duration_mins']) {
                    if (element['general_settings']['time_interval_hrs'] == clinic['general_settings']['time_interval_hrs']) {
                        if (element['general_settings']['time_interval_days'] == clinic['general_settings']['time_interval_days']) {
                            if (element['general_settings']['time_interval_mins'] == clinic['general_settings']['time_interval_mins']) {
                                if (element['general_settings']['retry_count'] == clinic['general_settings']['retry_count']) {
                                    return false;
                                }
                            }
                        }
                    }

                }
            }
        }

        return true;
    }
    closeModal() {
        this.dialogRef.close();
    }
}


@Component({
    selector: "alert-save-confirmation-modal",
    templateUrl: "./alert-save-confirmation-modal.html"
})

export class AlertOrderConfirmationComponent {
    yes: any;
    no: any;
    /**
     * Constructor
     *
     * @param {MatDialogRef<DialogComponent>} dialogRef
    */
    constructor(
      public dialogRef: MatDialogRef<ActivateDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    closeModal(param): void {
        this.dialogRef.close(param);
      }
}

@Component({
    selector: "alert-delete-confirmation",
    templateUrl: "./alert-delete-confirmation.html"
})


export class AlertDeleteConfirmationComponent implements OnInit {

    yes: any;
    no: any;
    /**
     * Constructor
     *
     * @param {MatDialogRef<DialogComponent>} dialogRef
    */
    constructor(
      public dialogRef: MatDialogRef<ActivateDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }
  
    ngOnInit() {
    }
    closeModal(param): void {
      this.dialogRef.close(param);
    }
  
  }
