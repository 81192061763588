import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { DialogComponent } from './dialog.component';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatCardModule, MatInputModule, MatProgressSpinnerModule, MatSelectModule, MatTabsModule, MatCheckboxModule } from '@angular/material';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivateDialogComponent } from './activate-dialog/activate-dialog.component';
import { StatusChangeDialogComponent } from './status-change-dialog/status-change-dialog.component';
import { NoNavigationComponent } from './no-navigation/no-navigation.component';
import { ZipOverwriteComponent } from './zip-overwrite/zip-overwrite.component';
import { ConfimerComponent } from './confimer/confimer.component';
import { AssessmentGeneralSettingsComponent } from './assessment-general-settings/assessment-general-settings.component';
import { SessionLogoutComponent } from './session-logout/session-logout.component';
import { ReloadComponent } from './reload/reload.component';
import { LocationCreateComponent } from 'app/features/main/clinics/location-create/location-create.component';
import { ClinicsModule } from 'app/features/main/clinics/clinics.module';
import { ListDialogComponent } from './list-dialog/list-dialog.component';

@NgModule({
    declarations: [
        DialogComponent,
        DeleteDialogComponent,
        ActivateDialogComponent,
        StatusChangeDialogComponent,
        NoNavigationComponent,
        ZipOverwriteComponent,
        ConfimerComponent,
        AssessmentGeneralSettingsComponent,
        SessionLogoutComponent,
        ReloadComponent,
        ListDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatCheckboxModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        ReactiveFormsModule
    ],
    entryComponents: [
        DialogComponent,
        ActivateDialogComponent,
        StatusChangeDialogComponent,
        DeleteDialogComponent,
        NoNavigationComponent,
        ZipOverwriteComponent,
        AssessmentGeneralSettingsComponent,
        ReloadComponent,
        ListDialogComponent
    ],
})
export class DialogModule {
}
